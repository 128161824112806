<template>
  <div id="app">
    <div class="content_bg">
      <div class="content_box">
        <div class="line_1">
          <div class="head_logo"></div>
          <div style="display: inline-block;position: absolute;left: 400px;top: 40px;cursor: pointer">
            <a class="a_link" href="#key1">About Us</a>
            <a class="a_link" href="#key2">Ecological Layout</a>
            <a class="a_link" href="#key3">Token Economy </a>
            <a class="a_link" href="#key4">Development Path</a>
            <a class="a_link" href="assets/White_papers.pdf" target="_blank">White papers</a>
          </div>
          <div class="search_box">
            <img src="./assets/search_icon.png" style="margin-top: 13px;margin-left: 30px;opacity: .6">
            <span style="margin-left: 35px;color: #cac9c9;position: absolute;top:17px">Search</span>
          </div>
          <div class="country"></div>
        </div>
        <div class="line_111"></div>
        <div class="line_2" id="key1">
          <div class="line1_bg"></div>
          <div style="margin-top: 130px;font-size: 78px;color: white;font-weight: bold">Based on Web3</div>
          <div>
            <img src="./assets/sports.png" width="332" height="120">
            <div style="font-size: 78px;color: white;font-weight: bold;position: absolute;left: 370px;top: 90px;z-index:
10">
              digital system
            </div>
          </div>
          <div>
            <p style="color: #c8c8c8;width: 770px;font-size: 24px">
              Aiming to comprehensively digitize the competitive sports
            </p>
            <p style="color: #c8c8c8;width: 770px;font-size: 24px">
              industry through blockchain and Al technology, in order to build
            </p>
            <p style="color: #c8c8c8;width: 770px;font-size: 24px">
              a metaverse competitive space that integrates entertainment,
            </p>
            <p style="color: #c8c8c8;width: 770px;font-size: 24px">
              social networking, and investment
            </p>
          </div>
        </div>
        <div class="line_3">
          <div style="font-size: 52px;color: white">JOINT INITIATING ORGANIZATION</div>
          <div style="font-size: 24px;color: #a5a5a5;line-height: 40px;margin-bottom: 100px">Three globally renowned
            companies jointly initiate APOLLO project
          </div>
          <div class="line3_item" style="position:absolute;left: 0px">
            <img src="./assets/3_1.png" width="452" height="353">
            <p style="font-size: 16px;font-weight: bold;color: white">Apollo Foundation</p>
            <p>The Apollo Foundation, registered and established in Cambridge, UK in 2020, focuses on combining
              blockchain technology with the competitive entertainment industry to provide more transparent, fair, and
              efficient solutions for the industry.</p>
            <p>The Apollo Foundation will promote the wider and deeper integration and application of the APOLLO network
              and APL Token in the field of competitive entertainment, while ensuring their security maintenance and
              sustainable development.</p>
          </div>
          <div class="line3_item" style="position:absolute;left: 472px">
            <img src="./assets/3_2.png" width="452" height="353">
            <p style="font-size: 16px;font-weight: bold;color: white">Apollo Global Asset Management Limited, UK</p>
            <p>Established in 2021, it is a global industrial finance group with main businesses including new energy,
              gambling, international trade, and financial investment.</p>
            <p>The company has offices in multiple cities around the world, including London, Chicago, Paris, Dubai,
              Mumbai, Kuala Lumpur, Seoul, Tokyo, Ho Chi Minh City, and Phnom Penh, forming a global business
              network.</p>
          </div>
          <div class="line3_item" style="position:absolute;left: 944px">
            <img src="./assets/3_3.png" width="452" height="353">
            <p style="font-size: 16px;font-weight: bold;color: white">OFF Chain Asset Management Co., Ltd., USA</p>
            <p>Established in 2008, headquartered in Colorado, USA, it is a global financial services institution.</p>
            <p>The company mainly provides asset management services to customers, including foreign exchange, precious
              metals, stocks, encrypted digital assets and other categories, with a managed asset scale of up to 20
              billion US dollars.</p>
            <p>In order to support the development and growth of the APOLLO ecosystem network, the company has injected
              $20 million in investment, providing ample financial support for APOLLO.</p>
          </div>
        </div>
        <div class="line_4">
          <div style="font-size: 52px;color: white">CORE TEAM</div>
          <div style="font-size: 24px;color: #a5a5a5;line-height: 40px;margin-bottom: 100px">The Apollo R&D team is
            composed of senior industry experts
          </div>
          <div class="carousel">
            <div class="carousel-item">
              <img src="./assets/1.png" alt="Eddy Hofmann">
              <div class="line4_txt">
                <h2>Eddy Hofmann</h2>
                <p>CEO</p>
                <p>Graduated from Oxford University and Humboldt University Berlin, he is an operations management
                  expert in the fields of encrypted digital and AI, with over ten years of industry experience. He has
                  managed more than 25 well-known blockchain projects and exchanges, and has worked for many Fortune 500
                  companies such as Mercedes Benz and Google. He has rich project management experience and excellent
                  leadership skills.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="./assets/2.png" alt="Alexander Johnson">
              <div class="line4_txt">
                <h2>Alexander Johnson</h2>
                <p>CMO</p>
                <p>He is a serial entrepreneur who has ventured into multiple fields such as entertainment, gambling,
                  and financial investment. His joining has brought rich industry resources to the team, and with unique
                  insights and planning thinking, he provides strategic direction for the team's development.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="./assets/3.png" alt="Tommy Wright">
              <div class="line4_txt">
                <h2>Tommy Wright</h2>
                <p>CTO</p>
                <p>Senior software development engineer, data privacy and cryptography expert,
                  has participated in the development of multiple Web3 projects. His technical background and
                  professional knowledge provide solid support for the team's technical research and development.</p>
              </div>
            </div>
            <!-- 添加更多成员的 carousel-item -->
          </div>
        </div>
        <div class="line_5_1" id="key2">
          <div style="font-size: 52px;color: white">6 MAJOR ECOLOGICAL LAYOUTS</div>
          <div style="font-size: 24px;color: #a5a5a5;line-height: 40px;margin-bottom: 100px">APOLLO has conducted a
            comprehensive: ecological layout and long-term strategic planning, aiming to build an openmultidimensional,
            and interesting competitive entertainment ecosystem for users
          </div>
        </div>
        <div class="line_5"></div>
        <div class="line_6">
          <div style="font-size: 52px;color: white">COMPETITIVE FINANCE</div>
          <div style="font-size: 24px;color: #a5a5a5;line-height: 40px;margin-bottom: 100px">Share the dividends of
            APOLLO ecosystem development with users, promote steady growth of user wealth and rapid development of the
            platform.
          </div>
          <div class="line6_1">
            <div class="_61">
              <p style="margin-top: 130px;font-size: 24px;font-weight: bold">Sports events</p>
              <p style="font-size: 14px;padding-left: 5px;padding-right: 5px">APOLLO collaborates with major sports
                event organizations to launch sports event based guessing products. Users earn corresponding profits by
                predicting the outcome of the event.</p>
            </div>
            <div class="_62">
              <p style="margin-top: 130px;font-size: 24px;font-weight: bold">Competitive game</p>
              <p style="font-size: 14px;padding-left: 5px;padding-right: 5px">APOLLO has developed a series of
                investment fund products based on competitive games. Users can play games with other users in these
                products and gain profits through strategies and techniques.</p>
            </div>
            <div class="_63">
              <p style="margin-top: 130px;font-size: 24px;font-weight: bold">Game revenue generation</p>
              <p style="font-size: 14px;padding-left: 5px;padding-right: 5px">APOLLO will develop games based on the
                metaverse to generate revenue. Users can obtain in-game assets by participating in games, completing
                tasks, or interacting with other users.</p>
            </div>
            <div class="_64">
              <p style="margin-top: 130px;font-size: 24px;font-weight: bold">Arbitrage of encrypted digital assets</p>
              <p style="font-size: 14px;padding-left: 5px;padding-right: 5px">APOLLO also provides arbitrage services
                based on encrypted digital assets, utilizing market fluctuations to generate profits.</p>
            </div>
          </div>
        </div>
        <div class="line_6" id="key3">
          <div style="font-size: 52px;color: white">APLTOKEN ECONOMICS</div>
          <div style="font-size: 24px;color: #a5a5a5;line-height: 40px;margin-bottom: 100px">
            APL is a universal token on the APOllO platform, used for GAs consumption, community governancemarket
            incentives, and ecological construction
          </div>
          <img src="./assets/7.png" width="426" height="462">
          <div class="_75" style="">
            <p>
              75%
            </p>
            <p>
              PLEDGE INCOME
            </p>
          </div>
          <div class="_25">
            <div>
              <p>
                10%
              </p>
              <p>
                INSTITUTIONAL INVESTMENT
              </p>
            </div>
            <div style="color: #fdfdfb">
              <p>
                5%
              </p>
              <p>
                ECOLOGICAL CONSTRUCTION
              </p>
            </div>
            <div style="color: #ea01fa">
              <p>
                4%
              </p>
              <p>
                NODE PRIVATE EQUITY
              </p>
            </div>
            <div style="color: #1f01fd">
              <p>
                3%
              </p>
              <p>
                THE DEVELOPMENT TEAM
              </p>
            </div>
            <div style="color: #c5c5c5">
              <p>
                4%
              </p>
              <p>
                COMMUNITY GOVERNANCE
              </p>
            </div>
          </div>
        </div>
        <div class="line_9">
          <div style="font-size: 52px;color: white">COMMUNITY AND GOVERNANCE</div>
          <div class="_91" style="text-align: left;margin-top: 200px">
            <img src="./assets/91.jpg" width="600px">
            <div style="position: absolute;right: 150px;width: 500px;top: 100px;text-align: right">
              <p style="font-weight: bold;font-size: 36px">Global operations,</p>
              <p style="font-size: 24px;font-weight: normal;color: #a5a5a5">Radiated to over 30 countries and
                regionswith participation from over 100 communities</p>
            </div>
          </div>
          <div class="_91" style="text-align: right;">
            <div style="width: 500px;text-align: left">
              <p style="font-weight: bold;font-size: 36px">Community building</p>
              <p style="font-size: 24px;font-weight: normal;color: #a5a5a5">DAO members are responsible for community
                management and operation, regularly organizing online and offline activities to provide users with a
                platform for communication, sharing, and feedback.</p>
            </div>
            <img src="./assets/92.jpg" width="600px" style="position: absolute;right: 0;top: -100px">
          </div>
          <div class="_91" style="text-align: left;">
            <img src="./assets/93.jpg" width="600px">
            <div style="position: absolute;right: 150px;width: 500px;top: 100px;text-align: right">
              <p style="font-weight: bold;font-size: 36px">Governance mechanism,</p>
              <p style="font-size: 24px;font-weight: normal;color: #a5a5a5">Adopting a decentralized governance
                mechanism to ensure the fairness and transparency of the project.</p>
            </div>
          </div>
          <div class="_91" style="text-align: right;">
            <div style="width: 500px;text-align: left">
              <p style="font-weight: bold;font-size: 36px">Community incentives</p>
              <p style="font-size: 24px;font-weight: normal;color: #a5a5a5">Establish a community treasury and incentive
                mechanism to encourage contributions and participation from community members, and provide rewards to
                developers, content creators, community managers, and others who contribute to the project.</p>
            </div>
            <img src="./assets/94.jpg" width="600px" style="position: absolute;right: 0;top: -100px">
          </div>
        </div>
        <div class="line_10" id="key4">
          <div style="font-size: 52px;color: white">Development route</div>
          <div class="time_line_box">
            <div class="time_line"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
          </div>
          <div style="margin-top: 50px"></div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2020</div>
            <div style="font-size: 16px;color: #c1c1c1">In 2020, the APPLLO Foundation was established, dedicated to investment and technological innovation in the field of competitive entertainment.</div>
          </div>
          <div class="line10_txt_left">
            <div style="font-size: 30px;color: white;font-weight: bold">2023</div>
            <div style="font-size: 16px;color: #c1c1c1">
              In 2023, APOLLO will build its infrastructure and carry out product development.</div>
          </div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2024 Q1</div>
            <div style="font-size: 16px;color: #c1c1c1">Obtained strategic investments from multiple institutions</div>
          </div>
          <div class="line10_txt_left">
            <div style="font-size: 30px;color: white;font-weight: bold">2024 Q3</div>
            <div style="font-size: 16px;color: #c1c1c1">
              Global community nodes start recruitingelobalcommunity nodes start recruiting</div>
          </div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2024 Q4</div>
            <div style="font-size: 16px;color: #c1c1c1">
              Presentation of basic products and functional moduleslaunch of competitive wealth management package, APllisted on the exchange</div>
          </div>
          <div class="line10_txt_left">
            <div style="font-size: 30px;color: white;font-weight: bold">2025 Q1</div>
            <div style="font-size: 16px;color: #c1c1c1">
              Esports events and open cooperation</div>
          </div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2025 Q2</div>
            <div style="font-size: 16px;color: #c1c1c1">
              APOLLO's gaming ecosystem launches</div>
          </div>
          <div class="line10_txt_left">
            <div style="font-size: 30px;color: white;font-weight: bold">2025 Q3</div>
            <div style="font-size: 16px;color: #c1c1c1">
              APOLLO Public Chain Test interaction, Esports Metaverse Deployment</div>
          </div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2025 Q4</div>
            <div style="font-size: 16px;color: #c1c1c1">
              Public chain ecosystem access, APL mapping mainnet and launching multiple centralized exchanges</div>
          </div>
          <div class="line10_txt_left">
            <div style="font-size: 30px;color: white;font-weight: bold">2026 Q1</div>
            <div style="font-size: 16px;color: #c1c1c1">
              APOLLO Metaverse Space offers features such as open land subscription and role-playing</div>
          </div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2026 Q2</div>
            <div style="font-size: 16px;color: #c1c1c1">
              Launching digital human replicas, wearable devices combined with immersive virtual reality experiences</div>
          </div>
          <div class="line10_txt_left">
            <div style="font-size: 30px;color: white;font-weight: bold">2026 Q3</div>
            <div style="font-size: 16px;color: #c1c1c1">
              ARealize global user base of over 90 million and promote ecological prosperity and aevelopment</div>
          </div>
          <div class="line10_txt_right">
            <div style="font-size: 30px;color: white;font-weight: bold">2027</div>
            <div style="font-size: 16px;color: #c1c1c1">
              Continuously optimizing and expanding the ecosystem,driving APOLLO to become a leading global metaverse platform</div>
          </div>
        </div>
        <div class="line_11">
          <div style="font-size: 52px;color: white">STRATEGIC COOPERATION</div>
          <div class="line11_content"></div>
        </div>
        <div class="line_12">
          <div style="font-size: 52px;color: white">MEDIA COOPERATION</div>
          <div class="line12_content"></div>
        </div>
      </div>
      <div class="line-with-gradient" style="position: absolute;bottom: 150px"></div>
      <div style="position: absolute;bottom: 0;width: 1400px;margin-left:calc((100vw - 1400px)/2);height: 150px;">
        <div style="color: #8f8f8f;font-size: 18px;margin-top: 30px;line-height: 40px">
          <div>Copyright ◎ 2024 APOLLO</div>
          <div>All rights reserved l Privacy</div>
          <div class="foot_bg"></div>
          <div class="us_bg"></div>
        </div>
      </div>
    </div>
    <div class="line_8">
      <div class="line-with-gradient"></div>
      <div class="line8_content" style="font-size: 40px;font-weight: bold;color: white">
        <div style="margin-top: 15px">Deflation</div>
        <div>destruction</div>
        <div style="position: absolute;right: 0;font-size: 16px;width: 1100px;top: 40px;font-weight: normal">On chain
          mutual transfer 1% destruction, platform profit regular repurchase and destruction, competitive scene
          application destruction, and subsequent ecological destruction of DeFi, gaming, metaverse and other
          applications.
        </div>
      </div>
      <div class="line-with-gradient"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  methods: {
    tts() {
      // 获取轮播项元素
      const carouselItems = document.querySelectorAll('.carousel-item');
      let currentIndex = 0;

// 显示指定索引的轮播项
      function showItem(index) {
        // 遍历所有轮播项，移除 active 类
        carouselItems.forEach(item => item.classList.remove('active'));
        // 为指定索引的轮播项添加 active 类
        carouselItems[index].classList.add('active');
      }

// 切换到下一个轮播项
      function nextItem() {
        currentIndex++;
        if (currentIndex >= carouselItems.length) {
          currentIndex = 0;
        }
        showItem(currentIndex);
      }

// 在 DOM 加载完成后执行初始化操作
      document.addEventListener('DOMContentLoaded', () => {
        setInterval(nextItem, 3000);
        showItem(currentIndex);
      });
    }
  },
  mounted() {
    this.tts()
  }
}
</script>
<style>
.head_logo{
  position: relative;
  background-image: url("./assets/logo.png");
  width: 327px;
  height: 61px;
  background-repeat: no-repeat;
}
a{
  border-right: 2px solid #ffffff;
  padding-right: 5px;

}
a:link,
a:visited {
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}
.a_link{
  color: white;
  margin-left: 10px;
}

.foot_bg{
  background-image: url("./assets/logo.png");
  width: 155px;
  height: 73px;
  position: absolute;
  top: 30px;
  left: 600px;
  background-repeat: no-repeat;
}
.us_bg{
  background-image: url("./assets/us.png");
  width: 377px;
  height: 49px;
  position: absolute;
  top: 50px;
  right: 80px;
}
.line11_content{
  background-image: url("./assets/11.png");
  width: 1300px;
  height: 255px;
  margin-left: 50px;
  margin-top: 150px;
}
.line12_content{
  background-image: url("./assets/12.png");
  width: 1300px;
  height: 241px;
  margin-left: 50px;
  margin-top: 150px;
}
.line_12{
  position: relative;
  text-align: center;
  margin-top: 160px;
  color: white;
}
.line_11{
  position: relative;
  text-align: center;
  margin-top: 10px;
  color: white;
}
.line10_txt_left{
  text-align: right;
  width: 690px;
  height: 100px;
}
.line10_txt_right{
  text-align: left;
  padding-left: 730px;
  height: 105px;
}
.circle_1{
  width: 12px;
  height: 12px;
  background-color: #232335;
  border-radius: 50%;
  margin-left: 696px;
  margin-bottom: 90px;
}
.time_line_box{
  position: absolute;
  margin-top: 150px;
  top: 0;
  left: 0;
}
.time_line{
  position: absolute;
  border-right: 4px solid #232335;
  width: 700px;
  height: 1350px;
}
.line_10{
  color: white;
  height: 1600px;
  text-align: center;
  margin-top: 150px;
  position: relative;
}
._91 {
  position: relative;
  margin-top: 30px;
}

.line_9 {
  color: white;
  position: relative;
  text-align: center;
  margin-top: 410px;
  height: 2000px;
}

.line8_content {
  position: relative;
  height: 120px;
  width: 1400px;
  margin: 0 auto;
}

.line_8 {
  position: absolute;
  top: 5500px;
  left: 0;
  width: 100%;
}

.line-with-gradient {
  position: relative;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #1f01fd, #ea01fa);
}

._75 {
  position: absolute;
  top: 570px;
  left: 250px;
  color: #f5cb7c;
  font-weight: bold
}

._25 {
  position: absolute;
  top: 235px;
  right: 160px;
  color: #f5cb7c;
  font-weight: bold;
  text-align: right;

}

._61 {
  background-image: url("./assets/61.png");
  width: 600px;
  height: 257px;
  display: inline-block;
}

._62 {
  background-image: url("./assets/62.png");
  width: 600px;
  height: 257px;
  display: inline-block;
  margin-left: 90px;
}

._63 {
  background-image: url("./assets/63.png");
  width: 600px;
  height: 257px;
  display: inline-block;
}

._64 {
  background-image: url("./assets/64.png");
  width: 600px;
  height: 257px;
  display: inline-block;
  margin-left: 90px;
}

.line6_1 div {
  margin-top: 30px;
}

.line6_1 {
  position: relative;
}

.line_6 {
  position: relative;
  text-align: center;
  margin-top: 210px;
  color: white;
}

.line_5 {
  background-image: url("./assets/5.png");
  width: 1400px;
  height: 558px;
  background-repeat: no-repeat;
  background-size: 100% 100%; /* 拉伸至容器的大小 */
}

.line4_txt {
  position: absolute;
  top: 30px;
  left: 450px;
  text-align: left;
}

.line3_item {
  display: inline-block;
  position: relative;
  width: 452px;
  height: 340px;
  color: #a5a5a5;
}

.line3_item p {
  font-size: 12px;
}

.line3_item span {
  display: inline-block;
  width: 400px;
  margin-left: 15px;
}

.line3_title {
  text-align: center;
}

.line_5_1 {
  text-align: center;
  margin-top: 210px;
  color: white;
}

.line_4 {
  text-align: center;
  margin-top: 210px;
  color: white;
}

.line_3 {
  text-align: center;
  margin-top: 210px;
  height: 700px;
}

.line1_bg {
  position: absolute;
  right: 0;
  top: -30px;
  background-image: url("./assets/line1.png");
  width: 666px;
  height: 588px;
}

.line_2 {
  position: relative;
}


.country {
  position: absolute;
  left: 1315px;
  background-image: url("./assets/country.png");
  width: 60px;
  height: 60px;
  top: 20px;
}

.search_box {
  position: absolute;
  left: 1100px;
  background-image: url("./assets/search.png");
  width: 197px;
  height: 52px;
  top: 20px;
}

.line_logo {
  display: inline-block;
}

.line_1 {
  position: fixed;
  padding-top: 20px;
  color: white;
  width: 100%;
  top: 0;
  z-index: 99;
  background-image: url("./assets/bg.png");
}
.line_111 {
  position: relative;
  padding-top: 20px;
  color: white;
  width: 100%;
  height: 100px;
}
.content_box {
  position: relative;
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 250px;
}

.content_bg {
  position: relative;
  width: 100%;
  background-image: url("./assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; /* 拉伸至容器的大小 */
}

body {
  margin: 0;
  padding: 0;
}

.carousel {
  position: relative;
  width: 800px;
  height: 400px;
  margin: 0 auto;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item img {
  width: 315px;
  height: 403px;
  object-fit: cover;
  margin-top: 0;
  margin-left: 30px;
  display: block;
}

.carousel-item h2 {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
}

.carousel-item p {
  text-align: center;
  font-size: 16px;
  margin: 10px 0;
}
</style>
